.logo {
  width: 165px;
}
.nav {
  margin-top: 30px;
}
*{
  font-family: 'Lato', sans-serif;
}
html{
  scroll-behavior: smooth;
} 
.customPadding {
  padding-left: 8.5rem;
  padding-right: 6.5rem;
}
.customWidth {
  max-width: 79rem;
}
.customNavBar {
  justify-content: center;
  align-items: center;
}
.leading-10 {
  line-height: 1.40;
}

.hei {
  max-width: 42rem;
}
.padd {
  padding: 0 22rem;
}
/* This only changes this particular animation duration */
.animate__animated.animate__fadeInDownBig {
  --animate-duration: 1s;
}
.titles {
  font-family: 'Lato', sans-serif;
}
.landing {
  font-family: 'Lato', sans-serif;
}
.W-Custom {
  width: 8rem;
}
.heightt  {
  line-height: 1.35;
}
.aboutus {
  width: 266px;
  bottom: -83px;
  left: 820px;
  height: auto;
  max-width: 100%;
}
.div-cursor {
  cursor: grab;
}